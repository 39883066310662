<template>
  <div>
      <SecondNavBar/>
      <!-- <h1 class="purple-title text-center">Videos</h1>
      <p>Coming Soon</p> -->
<h1 class="purple-title text-center" style="font-size:2rem;">Video Tutorials for Users</h1>
<div class="row">
  <div class="col-md-6 offset-md-3">
    <p class="text-center">The following video tutorials provide detailed information on the Home for Life Design® Home Assessment tool.</p>
    <p class="text-center">University Partners can refer to the "Professor's Guide" for how to use these videos with students in the classroom.</p>
  </div>
</div>
<div class="row">

  <div class="col-md-2"></div>
  <!-- Why this, why now? -->
  <div class="col-md-2">
  <video oncontextmenu="return false;" id="videoPlayer" controls controlsList="nodownload" preload="auto" width="100%" height="480" poster="" data-setup="{}">
    <source src="https://hflstorage.blob.core.windows.net/documents/Why This, Why Now.mp4" type='video/mp4' />
</video>
<p class="purple-title" style="font-size:19px;">Why this, why now?</p>
<p>The research and development of the home assessment tool.</p>
</div>
<div class="col-md-1"></div>
<!-- The client detail section  -->
<div class="col-md-2">
  <video oncontextmenu="return false;" id="videoPlayer2" controls controlsList="nodownload" preload="auto" width="100%" height="480" poster="">
    <source src="https://hflstorage.blob.core.windows.net/documents/Client Detail Section.mp4" type="video/mp4">
  </video>
  <p class="purple-title" style="font-size:19px;">The client detail section</p>
  <p>How to enter client information.</p>
</div>
<div class="col-md-1"></div>
<!-- Part1 Video -->
<div class="col-md-2">
  <video oncontextmenu="return false;" id="videoPlayer3" controls controlsList="nodownload" preload="auto" width="100%" height="480" poster="">
    <source src="https://hflstorage.blob.core.windows.net/documents/part1video.mp4" type="video/mp4">
  </video>
  <p class="purple-title" style="font-size:19px;">Part 1: Accessing the rooms for safety and accessibility.</p>
  <p>Details on how to become a reliable rater.</p>
</div>
</div>
<!-- Videos Second Row -->
<div class="row">
  <div class="col-md-2"></div>
    <!-- Part 2 -->
  <div class="col-md-2">
  <video oncontextmenu="return false;" id="videoPlayer4" controls controlsList="nodownload" preload="auto" width="100%" height="480" poster="" data-setup="{}">
    <source src="https://hflstorage.blob.core.windows.net/documents/Part 2 Assessing the Rooms for Safety and Accessibility.mp4" type='video/mp4' />
</video>
<p class="purple-title" style="font-size:19px;">Part 2: Accessing the rooms for safety and accessibility.</p>
<p>Details on how to become a reliable rater.</p>
</div>
<div class="col-md-1"></div>
<!-- Part 3 Video -->
<div class="col-md-2">
  <video oncontextmenu="return false;" id="videoPlayer5" controls controlsList="nodownload" preload="auto" width="100%" height="480" poster="" data-setup="{}">
    <source src="https://hflstorage.blob.core.windows.net/documents/Part 3 Assessing the rooms for safety and accessibility.mp4" type='video/mp4' />
</video>
<p class="purple-title" style="font-size:19px;">Part 3: Assessing the room for safety and accessibility.</p>
<p>Details on how to become a reliable rater.</p>
</div>
<div class="col-md-1"></div>
<!-- Home recommendations using space video -->
<div class="col-md-2">
  <video oncontextmenu="return false;" id="videoPlayer6" controls controlsList="nodownload" preload="auto" width="100%" height="480" poster="" data-setup="{}">
    <source src="https://hflstorage.blob.core.windows.net/documents/Home Recommendations Using the SPACE Formula.mp4" type='video/mp4' />
</video>
<p class="purple-title" style="font-size:19px;">Home Recommendations using the SPACE (Safe Personalized Accessible and Cost Effective) formula</p>
<p>How to make recommendations for the home.</p>
</div>

</div>
<!-- Video Third Row -->
<div class="row mb-4">
  <div class="col-md-2"></div>
  <!-- Gathering Requirements on Mobile Device --> 
  <div class="col-md-2">
     <video oncontextmenu="return false;" id="videoPlayer5" controls controlsList="nodownload" preload="auto" width="100%" height="480" poster="" data-setup="{}">
    <source src="https://hflstorage.blob.core.windows.net/documents/Gathering data and photos on a mobile device.mp4" type='video/mp4' />
</video>
<p class="purple-title" style="font-size:19px;">Gathering data and photos on a mobile device</p>
<p>How to gather photos and data in real-time.</p>
  </div>
</div>

<h1 class="purple-title text-center" style="font-size:2rem;">Frequently Asked Questions</h1>
<div class="row mt-4 mb-4">
<div class="col-md-6 offset-md-3">
<div class="accordion" id="faqAccordion">
  <div class="card" v-for="(faq, index) in faqs" :key="faq.question">
    <div class="card-header" :id="'header' +index">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" data-toggle="collapse" :data-target="'#collapse'+ index" aria-expanded="false" :aria-controls="'collapse'+ index">
          {{faq.question}}
        </button>
      </h2>
    </div>

    <div :id="'collapse'+ index" class="collapse" :aria-labelledby="'header' + index" data-parent="#faqAccordion">
      <div class="card-body" v-html="faq.answer">
      </div>
    </div>
  </div>


</div>
</div>
</div>

</div>
</template>

<script>
import SecondNavBar from '../../components/Clients/SecondNavBar'
import {contentService} from '../../_services/content.service'
export default {
    components:{
        SecondNavBar
    },
    data(){
      return{
        faqs: [{question: '', answer: ''}]
      }
    },

    mounted(){
      contentService.getFaqs()
      .then((response) => {
        this.faqs = response;
        console.log(this.faqs);
      })

    }
}
</script>

<style>

</style>